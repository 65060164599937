import type { Entry } from 'contentful'
import slugify from '@sindresorhus/slugify'
import type { IconName, IconPrefix } from '@shc/ui'
import type {
  TypeIconBrandFields,
  TypeIconDuotoneFields,
  TypeIconFields,
} from '@lib/generated-types'

const typeToPrefix: Record<string, IconPrefix> = {
  icon: 'fas',
  iconDuotone: 'fad',
  iconBrand: 'fab',
  iconCustom: 'fak',
}

export const getIconFromContentful = (
  icon?: Entry<TypeIconFields | TypeIconDuotoneFields | TypeIconBrandFields>
) => {
  if (!icon) return null
  const prefix = typeToPrefix[icon.sys?.contentType?.sys?.id]
  if (!prefix) return null
  const name = slugify(icon.fields.icon)
  return [prefix, name] as [IconPrefix, IconName]
}
