import { BLOCKS, INLINES, MARKS, Document, Block, Inline } from '@contentful/rich-text-types'
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getIcon } from '@components/icons'

import { isInternalHref } from '@lib/menu-item-mapper'
import { contentTypeBasePathMap, indexPageSlug } from '@lib/constants'
import A from '@components/anchor'
import H from '@components/heading'

export const renderMark: Options['renderMark'] = {
  [MARKS.BOLD]: (text) => <strong>{text}</strong>,
  [MARKS.ITALIC]: (text) => <em>{text}</em>,
}

export const renderNode: Options['renderNode'] = {
  [BLOCKS.HEADING_1]: (_node, children) => (
    <H level={1} size="h1">
      {children}
    </H>
  ),
  [BLOCKS.HEADING_2]: (_node, children) => (
    <H level={2} size="h2">
      {children}
    </H>
  ),
  [BLOCKS.HEADING_3]: (_node, children) => (
    <H level={3} size="h3">
      {children}
    </H>
  ),
  [BLOCKS.HEADING_4]: (_node, children) => (
    <H level={4} size="h4">
      {children}
    </H>
  ),
  [BLOCKS.HEADING_5]: (_node, children) => (
    <H level={5} size="h5">
      {children}
    </H>
  ),
  [BLOCKS.HEADING_6]: (_node, children) => (
    <H level={6} size="h6">
      {children}
    </H>
  ),
  [BLOCKS.PARAGRAPH]: (_node, children) => <p className="empty:hidden">{children}</p>,
  [BLOCKS.HR]: () => <hr />,
  [BLOCKS.OL_LIST]: (_node, children) => <ol>{children}</ol>,
  [BLOCKS.UL_LIST]: (_node, children) => <ul>{children}</ul>,
  [BLOCKS.LIST_ITEM]: (_node, children) => <li>{children}</li>,
  [INLINES.HYPERLINK]: (node, children) => {
    const isInternal = isInternalHref(node.data.uri)
    // eslint-disable-next-line react/jsx-no-target-blank
    return (
      <A
        href={node.data.uri}
        target={isInternal ? '_self' : '_blank'}
        rel={isInternal ? '' : 'noopener noreferrer'}
        underline>
        {children}
      </A>
    )
  },
  [INLINES.ENTRY_HYPERLINK]: (node, children) => {
    if (!node.data.hasOwnProperty('target')) {
      return <span>{children}</span>
    }
    const basePath = contentTypeBasePathMap[node.data.target.sys.contentType.sys.id]
    const slug = node.data.target.fields.slug === indexPageSlug ? '/' : node.data.target.fields.slug
    const href = basePath === slug ? basePath : basePath + slug
    return (
      <A href={href} underline>
        {children}
      </A>
    )
  },
  [INLINES.EMBEDDED_ENTRY]: (node) => {
    const contentType = node.data.target.sys.contentType.sys.id
    if (contentType === 'icon') {
      return <FontAwesomeIcon icon={getIcon(node.data.target.fields.icon)} />
    }
  },
  [INLINES.ASSET_HYPERLINK]: (node, children) => {
    const url = node.data.target?.fields?.file?.url ?? ''

    if (url.length) {
      return (
        <A
          href={node.data?.target?.fields?.file?.url}
          target="_blank"
          rel="noopener noreferrer"
          underline>
          {children}
        </A>
      )
    } else {
      return children
    }
  },
}

export const renderText: Options['renderText'] = (text) =>
  text.split('\n').flatMap((text2, i) => [i > 0 && <br />, text2])

export const richTextRender = (content?: Document | Block | Inline) =>
  documentToReactComponents(content as Document, { renderMark, renderNode, renderText })
