import ListItem from '@components/list-item'
import Container from '@components/container'
import type { TypeListFields } from '@lib/generated-types'
import { ComponentPropsWithoutRef } from 'react'
import slugify from 'slugify'
import Content from '@components/content'
import { richTextRenderFactory } from '@lib/rich-text-options'

interface ListProps extends ComponentPropsWithoutRef<'section'>, TypeListFields {
  isEmbedded?: boolean
}

interface Fields {
  title: string
  description: any
}

const List = ({
  internalName,
  sectionLink,
  items,
  listStyle,
  className,
  isEmbedded = false,
  ...props
}: ListProps) => {
  return (
    <Container
      id={typeof sectionLink === 'undefined' ? undefined : slugify(sectionLink as string)}
      className={className}
      {...props}>
      <div>
        {items.map(({ fields }, index) => {
          const itemFields = fields as Fields
          return (
            <ListItem
              key={index}
              title={itemFields.title}
              description={
                itemFields.description && (
                  <Content className="scl-rich-text">
                    {richTextRenderFactory()(itemFields.description)}
                  </Content>
                )
              }
              order={listStyle === 'number' ? index + 1 : undefined}
            />
          )
        })}
      </div>
    </Container>
  )
}

export default List
