import { Collapsible, ExpandableContent as ExpandableContentSharp } from '@shc/ui'
import Analytics from '@components/analytics'
import { richTextRenderFactory } from '@lib/rich-text-options'
import type {
  TypeExpandableContentFields,
  TypeExpandableContentItemFields,
} from '@lib/generated-types'
import type { SPContext, SPEvent } from '@hooks/use-analytics'

const ExpandableContent = ({
  sectionLink,
  items,
}: TypeExpandableContentFields) => {
  const richTextRenderChildren = richTextRenderFactory()

  const analyticsEvent = (title: string, fields: any, sys: any) => {
    if (!title) {
      return {}
    }

    return {
      click: {
        name: 'component_click',
        data: {},
      } as SPEvent,
      contexts: [
        {
          name: 'component',
          data: {
            component_text: title,
          },
        },
        {
          name: 'content',
          data: {
            content_type: sys.contentType.sys.id,
            content_internal_name: fields.title,
            content_entry_id: sys.id,
            content_level: 'item',
          },
        }
      ] as SPContext[],
    }
  }

  return (
    <ExpandableContentSharp
      id={sectionLink}>
      {items.map(({ fields, sys }) => {
        const fieldsData = fields as TypeExpandableContentItemFields

        return (
          <Collapsible
            description={
              <>{richTextRenderChildren(fieldsData.description)}</>
            }
            heading={fieldsData.title}
            key={sys.id}
            slotProps={{
              headingContainer: analyticsEvent(
                fieldsData.title,
                fields,
                sys
              )
            }}
            slots={{
              headingContainer: Analytics
            }}
          />
      )})}
    </ExpandableContentSharp>
  )
}

export default ExpandableContent
