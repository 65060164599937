"use client"

import { ComponentPropsWithoutRef, ReactElement, ReactNode } from 'react'
import { Typography } from '@shc/ui'
import clsx from 'clsx'



export interface DuplexImageProps extends ComponentPropsWithoutRef<'div'> {
  variant?: 'hero' | 'standard'
  heading?: string
  headingLevel?: 1 | 2 | 3 | 4 | 5
  description?: string | ReactElement | ReactNode
  descriptionClassName?: string
  image?: ReactElement
  imagePosition?: 'left' | 'right'
  stackOrder?: 'Image above text' | 'Image below text'
}

const DuplexImage = ({
  variant = 'hero',
  heading,
  headingLevel = 1,
  description,
  descriptionClassName,
  image,
  imagePosition = 'left',
  stackOrder = 'Image above text',
  className,
  children,
  ...props
}: DuplexImageProps) => {
  return (
    <div
      className={clsx(
        'col-span-12 flex gap-5 lg:gap-8 xl:gap-[80px] items-center justify-between',
        stackOrder === 'Image above text' && 'flex-col',
        stackOrder === 'Image below text' && 'flex-col-reverse',
        imagePosition === 'left' && 'lg:flex-row',
        imagePosition === 'right' && 'lg:flex-row-reverse',
        className
      )}
      {...props}>
      <div
        className={clsx(
          'w-full',
          'lg:basis-6/12',
          variant === 'hero' && 'xl:basis-7/12',
          variant === 'standard' && 'xl:basis-6/12'
        )}>
        {image}
      </div>
      <div
        className={clsx(
          'w-full',
          'flex flex-col self-start lg:self-center scl-rich-text gap-3 md:gap-5 lg:basis-6/12',
          variant === 'hero' && 'xl:basis-5/12',
          variant === 'standard' && 'xl:basis-6/12'
        )}>
        {heading && <Typography variant={`h${headingLevel}`}>{heading}</Typography>}
        {description && (
          <div className={clsx('text-base font-medium', descriptionClassName)}>{description}</div>
        )}
        <div className="flex flex-col md:flex-row gap-5 w-full pt-2 empty:hidden">{children}</div>
      </div>
    </div>
  )
}

export default DuplexImage