import { type ComponentPropsWithoutRef } from 'react'
import omit from 'lodash/omit'
import type { TypeCaptionedMediaFields } from '@lib/generated-types'
import CaptionedMedia from '@components/captioned-media'
import { richTextRender } from '@lib/rich-text-base'
import { imageAssetMapper } from '@lib/image-asset-mapper'

export interface CaptionedMediaProps
  extends TypeCaptionedMediaFields,
    ComponentPropsWithoutRef<'figure'> {
  showCaption?: boolean
}

const CaptionedMediaCms = ({
  focalPoint,
  image,
  caption,
  aspectRatio = '16:9',
  showCaption = true,
  ...props
}: CaptionedMediaProps) => {

  return (
    <CaptionedMedia
      image={imageAssetMapper(image)}
      imageSizes={`${aspectRatio}`}
      {...omit(props, ['internalName'])}>
      {showCaption && richTextRender(caption)}
    </CaptionedMedia>
  )
}

export default CaptionedMediaCms
