import { ComponentPropsWithoutRef } from 'react'
import { RawImageType } from '@lib/types'

import { CaptionedMedia as UiComponent } from '@shc/ui'

export interface CaptionedMediaProps extends ComponentPropsWithoutRef<'figure'> {
  image?: RawImageType
  imageSizes?: any
  children?: any
  rounded?: boolean
}

const CaptionedMedia = ({
  image,
  imageSizes,
  children,
  rounded = false
}: CaptionedMediaProps) => {

  return (
    <UiComponent
      caption={children}
      aspectRatio={imageSizes}
      slotProps={{
        media: {
          alt: image?.description ? image?.description : image?.title,
          src: image?.file.url
        }
      }}
      rounded={rounded}
    />
  )
}

export default CaptionedMedia
