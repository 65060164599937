import clsx from 'clsx'
import { ComponentPropsWithRef } from 'react'
export interface ContentProps extends ComponentPropsWithRef<'div'> {
  textAlignment?: 'center' | 'left'
  listColumns?: '1' | '2' | '3'
}

const Content = ({
  textAlignment = 'left',
  listColumns = '1',
  children,
  className,
  ...props
}: ContentProps) => {
  const listClasses = clsx(
    listColumns === '1' && 'list-column-1',
    listColumns === '2' && 'list-column-2',
    listColumns === '3' && 'list-column-3'
  )

  const listAlignClasses = clsx(
    className,
    textAlignment === 'left' && 'text-left',
    textAlignment === 'center' && 'text-center list-center'
  )

  return (
    <div className={`${listClasses} ${listAlignClasses}`} {...props}>
      {children}
    </div>
  )
}

export default Content
