'use client'

import { BlockContext, type TContentContext } from '@lib/analytics'
import type { ReactNode } from 'react'

export interface BlockAnalyticsWrapperProps {
  readonly value: TContentContext
  readonly children: ReactNode
}

export default function BlockAnalyticsWrapper({ value, children }: BlockAnalyticsWrapperProps) {
  return <BlockContext.Provider value={value}>{children}</BlockContext.Provider>
}
