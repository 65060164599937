"use client"

import clsx from 'clsx'
import { ComponentPropsWithoutRef, useCallback, useEffect, useState } from 'react'
import YouTube from 'react-youtube'

interface VideoPlayerProps extends ComponentPropsWithoutRef<'figure'> {
  videoUrl?: string
  autoPlay?: boolean
  fullScreen?: boolean
  loop?: boolean
}

export const getYoutubeVideoIdFromUrl = (urlString?: string) => {
  // early return if undefined or bad url
  if (!urlString) return
  // catch errors parsing url
  try {
    const url = new URL(urlString)
    // check if v search parameter
    if (url.searchParams.has('v')) return url.searchParams.get('v') ?? undefined
    // check if /embed/ in path
    if (url.pathname.includes('/embed/')) return url.pathname.split('/').pop()
  } catch (e) {
    console.error('VideoPlayer: error getting video id from youtube url.')
  }
}

const VideoPlayer = ({
  videoUrl,
  fullScreen = false,
  autoPlay = false,
  loop = false,
  children,
  ...props
}: VideoPlayerProps) => {
  const videoId = getYoutubeVideoIdFromUrl(videoUrl)

  const opts = {
    height: '390',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: autoPlay ? 1 : 0,
      mute: autoPlay ? 1 : 0,
      controls: fullScreen ? 0 : 1,
      loop: loop ? 1 : 0,
      playlist: loop ? videoId : undefined,
      rel: 0,
      modestbranding: 1,
    },
  }

  // Maintain aspect ratio
  // How this works: The container element is given a zero height and a percentage bottom padding.
  // The percentage bottom padding is a percentage of the container width, so that gives it a fixed aspect ratio.
  // But in order to get the iframe to show up inside the zero-height container, you need to make the container
  // relative and the iframe absolute, positioned inside the div.

  const [isFullScreen, setisFullScreen] = useState(fullScreen)
  const containerClasses = 'w-full max-w-full relative h-0 pb-[56.25%]'
  const iframeClasses = clsx(
    'w-full max-w-full h-full inset-0',
    !isFullScreen && 'absolute',
    isFullScreen && 'fixed z-[9999]'
  )

  const escFunction = useCallback(
    (event: KeyboardEvent) => {
      if (fullScreen)
        if (event.key === 'Escape') {
          setisFullScreen(false)
        } else if (event.key === ' ') {
          setisFullScreen(true)
        }
    },
    [fullScreen]
  )

  useEffect(() => {
    document.addEventListener('keydown', escFunction, true)
    return () => {
      document.removeEventListener('keydown', escFunction, true)
    }
  }, [escFunction])

  return (
    // Add data-videoid for testing since library will not render iframe in node runtime
    <figure data-videoid={videoId} {...props}>
      <YouTube
        videoId={videoId}
        className={containerClasses}
        iframeClassName={iframeClasses}
        opts={opts}
        loading="lazy"
      />
      {/* Add empty:hidden class to simplify checking for the existence of children */}
      <figcaption className="text-sm text-left mt-3 empty:hidden">{children}</figcaption>
    </figure>
  )
}

export default VideoPlayer
