"use client"

import clsx from 'clsx'
import { ptMap, pbMap } from '@lib/token-maps'
import { init, Form } from '@feathery/react';
import { ComponentPropsWithoutRef, useState, useEffect } from 'react'
import Container from '@components/container'
import { useRouter } from 'next/navigation'
import { Icon, Button } from '@shc/ui'

export interface FeatheryFormProps extends ComponentPropsWithoutRef<'div'> {
    formTitle: string
    SDKKey: string
    formId: string
    displayType: string
    formCompleteUrl: string | undefined
    paddingTop: "0px" | "20px" | "40px" | "48px" | "60px";
    paddingBottom: "0px" | "20px" | "40px" | "48px" | "60px";
}

const FeatheryForm = ({ formTitle, SDKKey, formId, displayType, formCompleteUrl, paddingTop, paddingBottom }: FeatheryFormProps) => {
    const router = useRouter();
    const [formCompleted, setFormCompleted] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const [showPopup, setShowPopup] = useState(false);
    const popupOpts = displayType === 'Popup' ? { show: showPopup, onHide: () => setShowPopup(false) } : undefined;

    // Initialize Feathery
    init(SDKKey);
    useEffect(() => {
        if( formCompleted ){
            router.push(formCompleteUrl || '/thank-you' );
        }
    },[formCompleted, formCompleteUrl, router]);

    const loaderIcon = (
        <div className={'col-span-12 flex flex-col md:flex-row w-full justify-center'}>
            <Icon
                color="#42484d"
                icon="spinner-third"
                size="2x"
                spin
            />
        </div>
    );

    // Show the Feathery form
    return (
        <Container className={clsx('grid grid-cols-12', ptMap[paddingTop], pbMap[paddingBottom])}>
            <div className={'col-span-12 flex flex-col md:flex-row w-full feathery-form'}>
                { displayType === 'Popup' && (
                    <Button
                        id="feathery-form-cta"
                        aria-label=""
                        color="primary"
                        shape="rounded"
                        variant='filled'
                        size="sm"
                        width="auto"
                        onClick={() => setShowPopup(true)}
                        >
                        {formTitle}
                    </Button>
                )}
                { showLoader && loaderIcon }
                <Form 
                    formId={formId}
                    onLoad={() => setShowLoader(false)}
                    onFormComplete={() => setFormCompleted(true)}
                    className={'feathery-form'}
                    popupOptions={popupOpts}
                />
            </div> 
        </Container>
    )
}

export default FeatheryForm


