import * as Contentful from 'contentful'
import merge from 'lodash/merge'
import isEmpty from 'lodash/isEmpty'
import type { Json } from '@lib/types'

export interface DataLayer {
  dataLayer?: Json
}

declare global {
  interface Window {
    dataLayer: Array<any>
  }
}

export const dataLayerPush = (obj: Json):void => {
  if (!isEmpty(obj)) {
    window.dataLayer?.push(obj)
  }
}

/**
 * Merges multiple objects or arrays to allow overrides, addition keys, or multiple pushes.
 */
type JsonOrUndefined = Json | Json[] | Contentful.EntryFields.Object | undefined
export const dataLayerMergePush = (...records: JsonOrUndefined[]):void => {
  const merged = [{}]
  records.forEach((record) => {
    if (Array.isArray(record)) {
      record.forEach((item, itemIdx) => {
        merged[itemIdx] = merged[itemIdx] ?? {}
        merge(merged[itemIdx], item)
      })
    }
    else {
      merge(merged[0], record)
    }
  })
  merged.forEach(dataLayerPush)
}

export const pushVirtualPageView = (rest: Json) => {
  dataLayerMergePush({
    event: 'VirtualPageView'
  }, rest)
}

const service = {
  dataLayerMergePush,
  pushVirtualPageView,
}

export default service
