"use client"

import clsx from 'clsx'
import { ComponentPropsWithoutRef, ReactElement } from 'react'
import Heading from '@components/heading'
import { getIconFromContentful } from '@lib/icons'
import type { TypeIcon } from '@lib/generated-types'
import { Button, Collapsible, Icon } from '@shc/ui'
import url from 'url'
import { MouseEvent } from 'react'
import { dataLayerMergePush } from '@lib/services/gtm-service'
import { routeMapper } from '@lib/menu-item-mapper'
import { richTextRenderFactory } from '@lib/rich-text-options'

export interface CardAccordionProps extends ComponentPropsWithoutRef<'div'> {
  fieldsData: {
    buttonLink: any
    buttonAnchorOrExternalLink: string
    primaryIcon?: TypeIcon
    title: string
    summary?: string
    icon1?: TypeIcon
    icon1Text?: string
    icon2?: TypeIcon
    icon2Text?: string
    button: ReactElement
    accordionDropdownLabel: string
    accordionBody: any
    rightFooterText?: string
    isFeatured?: boolean
    featuredText?: string
    buttonStyle: "primary" | "light" | "transparent" | undefined
    buttonDataLayer: string
    buttonLabel: string
  }
}

const CardAccordion = ({
  fieldsData,
  className,
  ...props
}: CardAccordionProps) => {
  // const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
  const { route, isInternal } = routeMapper(
    fieldsData.buttonLink,
    fieldsData.buttonAnchorOrExternalLink
  )
  const primaryIcon = fieldsData.primaryIcon
  const icon1 = fieldsData.icon1
  const icon2 = fieldsData.icon2

  return (
    <div
      className={clsx(
        'bg-gray-50 rounded drop-shadow-md w-full gap-5',
        className,
        fieldsData.isFeatured && 'border-2 border-primary-700'
      )}
      {...props}>
      {fieldsData.featuredText && (
        <div className="text-center bg-primary-700 text-sm font-semibold text-white">
          {fieldsData.featuredText}
        </div>
      )}
      <article className="flex flex-col gap-5 items-center w-full h-full p-6">
        {/*
          To acheive vertical alignment of the content across multiple cards,
          the max height of this div is set to 150px (2 lines of text).
          Contentful 'summary' field is set to max 55 characters to enforce this.
        */}
        <div className="flex flex-col flex-grow gap-5 w-full max-h-[150px]">
          {primaryIcon && !fieldsData.isFeatured && (
            <Icon icon={getIconFromContentful(primaryIcon)} className="text-primary h-6" />
          )}
          {fieldsData.title && (
            <Heading
              level={3}
              size="h3"
              className={clsx(
                'text-center',
                !fieldsData.isFeatured && !primaryIcon && 'pt-[72px]',
                fieldsData.isFeatured && 'pt-[42px]'
              )}>
              {fieldsData.title}
            </Heading>
          )}
          {fieldsData.summary && <div className="text-base font-medium leading-7 text-center">{fieldsData.summary}</div>}
        </div>

        <div className="flex flex-col w-full gap-5">
          <div className="flex flex-col gap-5 items-center w-full">
            <div className="flex flex-row gap-4 md:gap-10 empty:hidden">
              {icon1 && fieldsData.icon1Text && (
                <div className="flex flex-row gap-2 items-center">
                  {icon1 && (
                    <Icon
                      fixedWidth
                      icon={getIconFromContentful(icon1)}
                      className="h-4 text-gray-700 text-base"
                    />
                  )}
                  {fieldsData.icon1Text && (
                    <div className="text-gray-700 text-base font-medium">{fieldsData.icon1Text}</div>
                  )}
                </div>
              )}
              {icon2 && fieldsData.icon2Text && (
                <div className="flex flex-row gap-2 items-center">
                  {icon2 && (
                    <Icon
                      fixedWidth
                      icon={getIconFromContentful(icon2)}
                      className="h-4 text-gray-700 text-base"
                    />
                  )}
                  {fieldsData.icon2Text && (
                    <div className="text-gray-700 text-base font-medium">{fieldsData.icon2Text}</div>
                  )}
                </div>
              )}
            </div>
            <Button
              href={route}
              target={isInternal ? undefined : '_blank'}
              rel={isInternal ? undefined : 'noopener noreferrer'}
              color={fieldsData.buttonStyle}
              size="md"
              shape="rounded"
              width="full"
              onClick={(e: MouseEvent<any>) => {
                dataLayerMergePush(
                  {
                    event: 'cta_click',
                    content_name: fieldsData.title,
                    link_text: e.currentTarget.innerText,
                    link_url: e.currentTarget.href,
                    link_domain: isInternal
                      ? window.location.host
                      : url.parse(e.currentTarget.href).host,
                  },
                  fieldsData.buttonDataLayer
                )
              }}>
              {fieldsData.buttonLabel}
            </Button>
          </div>

          {/* ACCORDION */}
          <Collapsible
            heading={fieldsData.accordionDropdownLabel}
            description={richTextRenderFactory()(fieldsData.accordionBody)}
          />
        </div>
      </article>
    </div>
  )
}

export default CardAccordion
