
import { RawImageType } from '@lib/types'
import { Asset } from 'contentful'


//TODO

// it should return undefined if I pass it undefined
// it should map a contentful asset to an image
  // does this mean you check every property on image?



export const imageAssetMapper = (value?: Asset): RawImageType | undefined => {

  if (typeof value === undefined) {
    return
  }

  const fields = value?.fields ?? {};

  const image: RawImageType = {
    title: fields.title,
    description: fields.description,
    file: {
      url: fields.file?.url,
      details: {
        size: fields.file?.details.size,
        image: {
          width:  fields.file?.details.image?.width,
          height: fields.file?.details.image?.height
        },
      },
    fileName: fields.file?.fileName,
    mimeType: fields.file?.contentType
    }
  }

  return image
}

export default imageAssetMapper
