'use client'

import { usePathname } from 'next/navigation'
import { type ComponentPropsWithoutRef } from 'react'
import { getIcon } from '@components/icons'
import type { Menu } from '@lib/types'
import useAnalytics from '@hooks/use-analytics'
import { Breadcrumbs as UIBreadcrumbs, Icon, Link } from '@shc/ui'

export interface BreadcrumbProps extends ComponentPropsWithoutRef<'nav'> {
  breadcrumbs: Menu
}

const Breadcrumbs = ({
  breadcrumbs,
  className,
  children,
  ...props
}: BreadcrumbProps) => {

  const activePathname = usePathname()
  const { track } = useAnalytics()

  return (
    <UIBreadcrumbs>
        {breadcrumbs.map(([link], idx, arr) => (
          <Link
            href={link.route}
            key={link.id}
            target={link.isInternal ? undefined : '_blank'}
            rel={link.isInternal ? undefined : 'noopener noreferrer'}
            noUnderline
            {...(link.route.toLowerCase() === activePathname ? { 'aria-current': 'page' } : {})}
            className="no-wrap text-sm"
            onClick={() => {
              track({
                event: {
                  name: 'navigation_click',
                  data: {
                    navigation_tree: arr
                      .slice(0, idx + 1)
                      .map(([item]) => item.name)
                      .join(' > ')
                      .trim(),
                    navigation_level: idx + 1,
                    navigation_subject: link.name,
                    navigation_url: link.route,
                  },
                },
                contexts: [{ name: 'section', data: { section_name: 'breadcrumb' } }],
              })
            }}>
            {link.icon !== undefined && (
              <Icon
                icon={getIcon(link.icon)}
                aria-hidden="false"
                aria-label={link.name}
                className="h-4"
              />
            )}
            {!link.icon && link.name}
          </Link>
        ))}
      </UIBreadcrumbs>
  )
}

export default Breadcrumbs
