"use client"

import clsx from 'clsx'
import { ComponentPropsWithoutRef, ReactElement } from 'react'
import Analytics from '@components/analytics'
import Heading from '@components/heading'
export interface BioProps extends ComponentPropsWithoutRef<'div'> {
  image?: ReactElement
  heading: string
  subHeading?: string
  body?: string
  link?: string
}

const Bio = ({
  image,
  heading,
  subHeading,
  body,
  link,
  className,
  ...props
}: BioProps) => {
  return (
    <div className={clsx('flex flex-row gap-5', className)} {...props}>
      {link ? (
        <>
          <Analytics
            click={{
              name: 'component_click',
              data: {},
            }}
            contexts={[
              {
                name: 'component',
                data: {
                  component_text: `Image link to ${heading}`,
                  component_url: link,
                },
              },
            ]}>
            <a
              href={link}
              aria-label={`Link to ${heading}'s Bio`}
              className="outline-primary outline-offset-2 focus:outline-2">
              {image}
            </a>
          </Analytics>
          <Analytics
            click={{
              name: 'component_click',
              data: {},
            }}
            contexts={[
              {
                name: 'component',
                data: {
                  component_text: `Name link to ${heading}`,
                  component_url: link,
                },
              },
            ]}>
            <a
              href={link}
              aria-label={`Link to ${heading}'s Bio`}
              className="outline-primary outline-offset-2 focus:outline-2">
              <div className="flex flex-col gap-3">
                <Heading level={3} size="h3" className="group-hover:underline">
                  {heading}
                </Heading>
                {subHeading && <div className="text-sm font-semibold">{subHeading}</div>}
                {body && <p className="text-sm font-medium">{body}</p>}
              </div>
            </a>
          </Analytics>
        </>
      ) : (
        <>
          {image}
          <div className="flex flex-col gap-3">
            <Heading level={3} size="h3">
              {heading}
            </Heading>
            {subHeading && <div className="text-sm font-semibold">{subHeading}</div>}
            {body && <p className="text-sm font-medium">{body}</p>}
          </div>
        </>
      )}
    </div>
  )
}

export default Bio
