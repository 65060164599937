import { ComponentPropsWithoutRef, ReactElement, ReactNode } from 'react'
import Heading from './heading'
import clsx from 'clsx'

export interface ListItemProps extends ComponentPropsWithoutRef<'div'> {
  title?: string
  description?: string | ReactElement | ReactNode
  order?: number
}

const ListItem = ({ title, description, order, className, ...props }: ListItemProps) => {
  return (
    <div
      className={clsx(
        'empty:hidden flex flex-row gap-5 pb-8 first:border-t border-b',
        typeof order === 'undefined' ? 'pt-8' : 'pt-7',
        className
      )}
      {...props}>
      {typeof order !== 'undefined' && (
        <div className="flex bg-primary-50 w-10 h-10 min-w-[2.5rem] rounded-full justify-center items-center">
          <Heading level={6} size="h6">
            {order}
          </Heading>
        </div>
      )}
      <div className="empty:hidden">
        {typeof title !== 'undefined' && (
          <Heading
            className="text-left flex flex-inline items-center justify-left"
            level={3}
            size="h3">
            {title}
          </Heading>
        )}
        <div className="text-left pt-2 empty:hidden">{description}</div>
      </div>
    </div>
  )
}

export default ListItem
